import tplLoader from "./tpl-loader.js";
import functions from './functions.js';
import calendar from './calendar.js';
import { SimpleGuests } from "./simple-guests.js";
import Slider from './Slider.js';
import exchangeRate from "./exchangeRate.js";


$(() =>
{
    details.init();
});

const details = {
    $form: null,
    form: null,
    response: [],
    calendars: [],
    rates: [],
    params: {
        objectId: null,
        objectTypeId: null,
        unitId: null,
        guests: '',
        dates: null,
        dateFrom: null,
        dateTo: null,
        objectGroupId: null,
        calcErrorMessages: null,
    },
    inquiryRequired: ['dates', 'firstName', 'lastName', 'email', 'phone', 'guests'],
    focusOnBooking: false,
    calcTotal: 0,
    trackingTotal: 0,
    init()
    {

        if (!$('.page-type-details')[0])
        {
            return;
        }
        let isMobile = functions.isMobile();
        

        this.$form = $('.unit-search-form')

        this.getCalculation()
        this.$form.on('submit', (e) =>
        {  
            e.preventDefault();
            if (details.params.objectGroupId && details.params.objectGroupId == 6)
            {
                if (details.params.objectTypeId == 49 || details.params.duration)
                {
                    this.getCalculation()
                }
            }
            else
            {
                let params = functions.getFormData($(e.currentTarget));
                if(params.dates)
                {
                    let val = params.dates.split(' - ');
                    details.params.dateFrom  = val[0];
                    details.params.dateTo    = val[1];
                    details.params.dates = null;
                }
                functions.setUrlData(details.params, true);
    
                this.getCalculation();
            }            
        })

        

        //  Get clear btn on change
        this.$form.on('change', this.onChange);

        // Remove clear button
        $('.dates-clear').on('click', function (e)
        {

            $('.dates-clear').addClass('d-none');
            $('.datepicker-range')[0]._flatpickr && $('.datepicker-range')[0]._flatpickr.setDate([])
            $('[name="dates"]')[0]._flatpickr && $('[name="dates"]')[0]._flatpickr.setDate([]);

            details.params.dateTo = details.params.dateFrom = null;
            details.params.dates = null;
            functions.setUrlData(details.params, true, true);
        })

        


        // $('.btn-submit').on('click', () => {
           
        //     this.showInfoText(this.params.dates, this.params.guests);
        // })

        // if (isMobile) {
        //     $('.nav-bottom').addClass('visible');

        //     $('.btn-select-date').on('click', function () {
        //         setTimeout(() => {
        //             $('.contact-select-mobile').addClass('d-none');
        //             $('.show-on-calendar').removeClass('d-none');
        //         }, 300);
        //     });

        //     $('.book-now-nav-bottom').on('click', function () {
        //         let wh = $(window).outerHeight();
        //         functions.scrollToElement($('.booking-scroll-hook'), wh);
        //     });


        // }

        const bookingSection =  $('#booking');

        // $('.benefit-close').on('click', e => {
        //     functions.setCookie('benefit_modal', 1);
        // });

        // let benefit_modal = functions.getCookie('benefit_modal');
        
        $(window).scroll(e => {
            
            // const offset = $('#booking').offset().top - $('#booking').outerHeight();
            
            
            $(window).scrollTop() > 200
            ? $('.nav-top').addClass('visible')
            : $('.nav-top').removeClass('visible');

            $(window).scrollTop() > 200
            ? $('.nav-bottom').addClass('visible')
            : $('.nav-bottom').removeClass('visible');
            
            if (bookingSection.length > 0) 
            {
                const locationOffset = $('#location').lenght ? $('#location').offset().top - $('#location').outerHeight() : 0;


                $(window).scrollTop() > locationOffset 
                ? $('.popup-el').addClass('visible')
                : $('.popup-el').removeClass('visible');   
                    
                const offsetTop = bookingSection.offset().top - bookingSection.outerHeight();
                const offsetBottom = bookingSection.offset().top + bookingSection.outerHeight();          
    
                let footerInViewport = $(window).scrollTop() + $(window).outerHeight() >= $('footer').offset().top;
    
                // $(window).scrollTop() > 200 && ($(window).scrollTop() < offsetTop || $(window).scrollTop() > offsetBottom)
                //     && !footerInViewport
                //     ? $('.nav-bottom').addClass('visible')
                //     : $('.nav-bottom').removeClass('visible');
            }
    
            });

       

        // Toggle tabs in modal window
        $('[data-bs-toggle="modal"][data-bs-tab]').on('click', e =>
        {

            const $target = $(e.currentTarget);
            const unitId = $target.attr('data-unitid');
            const $tab = $('#' + $target.attr('data-bs-tab') + '-link');

            $tab[0].click();
        });

        $('.btn-booking-align-bottom').on('click', function (e)
        {
            let wh = $(window).outerHeight();
            if (functions.isMobile()) functions.scrollToElement($('.booking-scroll-hook'), wh);
        })

        details.getParams();

        if (isMobile && details.datesSelected())
        {
            $('.select-dates-nav-bottom').addClass('d-none');
            $('.book-now-nav-bottom').removeClass('d-none');
        }

        // if(details.params.calendar) functions.scrollToElement($('#booking'), 0);

        // let params = functions.getUrlData(true);
        // details.params.guests = params.guests;

        details.params.objectGroupId = $('#booking').attr('data-objectgroupid');
        details.params.objectTypeId = $('#booking').attr('data-objecttypeid') || null;
        details.params.unitId = $('#booking').attr('data-unitid');
        details.params.objectId = $('#booking').attr('data-objectid') || null;
        details.params.duration = $('#booking').attr('data-duration') || null;

        //details.splitCalendarDates($('[name=dates]').val());

        details.calcErrorMessages = $('#booking').attr('data-calcErrorMessages') ? JSON.parse($('#booking').attr('data-calcErrorMessages')) : null;

        if ($('[data-calendarconfig]')[0])
        {
            details.calendarInit();
        }

        if (details.params.objectGroupId && details.params.objectGroupId == 6) this.initDepartureCalendar()

        if (details.params.dateFrom && details.params.dateTo)
        {
            let dates = [details.params.dateFrom, details.params.dateTo];

            if (details.params.objectGroupId && details.params.objectGroupId == 6)
            {
                if (details.params.objectTypeId == 49) delete dates[1];
            }

            $('.datepicker-range').each((index, elem) => {
                if ($(elem)[0]._flatpickr) $(elem)[0]._flatpickr.setDate(dates);
            })
            $('[name="dates"]').each((index, elem) => {
                if ($(elem)[0]._flatpickr) $(elem)[0]._flatpickr.setDate(dates);
            })

            if (dates) $('.dates-clear').removeClass('d-none');
            // if ($('[name="dates"]')[0]._flatpickr)
            // {

            //     $('[name="dates"]')[0]._flatpickr.setDate([details.params.dateFrom, details.params.dateTo]);
            // }
            // details.callbackOnDatesSelected(details.params.dateFrom, details.params.dateTo);
        }

        if (details.params.guests && $('.simple-guests')[0])
        {
            $('.simple-guests').each((index, elem) =>
            {
                elem._simpleGuests.setValue(details.params.guests);
            });
        }

        details.getCalculation();


        $('[name="dates"]').on('change', e =>
        {
            e.preventDefault();
            const $target = $(e.target);
            details.splitCalendarDates($target.val());

            if (details.params.objectGroupId == 1)
            {
                
                if (details.params.dateFrom && details.params.dateTo)
                {
                    details.focusOnBooking = true;
                    // $('.nav-bottom').removeClass('visible');
                    $('.select-dates-nav-bottom').addClass('d-none');
                    $('.book-now-nav-bottom').removeClass('d-none');
                }
            }

            if (details.params.objectGroupId && details.params.objectGroupId == 6)
            {
                if (details.params.duration)
                {
                    let dateTo = new Date(details.params.dateFrom);
                    dateTo.setDate(dateTo.getDate() + +(+details.params.duration-1));

                    details.params.dateTo = dateTo.toISOString().split('T')[0];

                    $('.datepicker-range').each((index, elem) => {
                        if ($(elem)[0]._flatpickr)
                        {
                            $(elem)[0]._flatpickr.setDate([details.params.dateFrom, details.params.dateTo]);
                            $(elem)[0]._flatpickr.close();
                        }
                    })

                    
                    
                }
                else if (details.params.objectTypeId == 49)
                {
                    if (details.params.dateFrom)
                    {
                        details.calendars.forEach((calendar) => {
                            let currCalId = $($target.closest('[data-calendarconfig]')).attr('data-calid');
                            if (currCalId != calendar.config.id) calendar.setDate(details.params.dateFrom)
                        })

                        details.params.dateTo = details.params.dateFrom;
                        this.getCalculation();
                    }
                }
            }
            
            functions.setUrlData(details.params, true, true);
        });

        // Exit popup
        $('.exit-popup').on('click', () => $('.popup-el').addClass('d-none'));
        $('.save-btn').on('click', () => $('.popup-el').addClass('d-none'));

        $('.btn-clear-dates').on('click', function (e)
        {

            details.calendars.forEach((calendar) => {
                calendar.setDate([])
            })
            details.params.dateTo = details.params.dateFrom = null;
            functions.setUrlData(details.params, true, true);
            $('.total-converted').addClass('d-none');
            $('.total-converted-default').removeClass('d-none');
            details.callbackOnDatesSelected(details.params.dateFrom, details.params.dateTo);

            $('.btn-booking').addClass('d-none');

            $('.calc-msg').addClass('d-none');

            $('.label-from').removeClass('d-none');
            $('.label-total').addClass('d-none');

            $('.no-calculation').removeClass('d-none');
            $('.calculation-text').addClass('d-none');
            $('.acc-label').removeClass('d-none');

            ['.price-old', '.price', '.price-old-converted', '.price-converted'].forEach((elem, i) =>
            {
                let $elem = $(elem);
                
                if ($elem.length)
                {
                    $elem.each((index, $elem2) => {
                        $elem2 = $($elem2);
                        
                        if ($elem.length && ! $elem2.parents('.card.v0').length)
                        {
                            let curr = $elem2.attr('data-currency');
                            let price = $elem2.attr('data-price');
                            if (price && price !== '0,00' && price !== '0.00' && price !== '0')
                            {
                                let formatedPrice = curr + ' ' + price;
                                if (i < 2) formatedPrice = price + ' ' + curr;
                                $elem2.html(formatedPrice);
                            }
                            else
                            {
                                $elem2.html('');
                            }
                        }
                    })
                }
            })
        });

        $('[name="guests"]').on('change', e =>
        {
            e.preventDefault();
            const $target = $(e.target);
            details.params.guests = $target.val();
            functions.setUrlData(details.params, true, true);
        });

        $(window).on('hashchange', e =>
        {
            details.getParams();
            if ($('[data-calendarconfig]')[0] && (details.params.objectGroupId && details.params.objectGroupId == 1)) details.getCalculation();
            // details.getCalculation();
        });

        details.loadReviews();
        details.inquiryInit();


        if (details.params.objectTypeId == 49) 
        {
            this.rates = $('[data-rates]').attr('data-rates') && JSON.parse($('[data-rates]').attr('data-rates')) || [];
        }


    },
    loadReviews()
    {
        $('.reviews-more').on('click', function (e)
        {
            let $button = $(e.currentTarget);
            let perPage = 3;
            let page = +$button.data('page') + 1;
            let total = Math.round(+$button.data('total') / perPage);

            $.post('/services/reviews/', { language: functions.getLang(), perPage: perPage, page: page, objectId: $button.data('objectid') }).then(response =>
            {
                if (response.status == true)
                {
                    $button.data('page', page);
                    if (total <= page)
                    {
                        $button.addClass('d-none');
                    }
                    $(".reviews").append(response.html);
                }
            });
        });
    },
    calendarInit()
    {
        $('[data-calendarconfig]').each((index, cal) =>
        {
            let elem = $(cal);
            let config = JSON.parse(elem.attr('data-calendarconfig'));

            elem.attr('data-calid', index);

            let defaultDate = $('[data-calendarconfig][data-defaultdate]').attr('data-defaultdate');
            let minDate = $('[data-calendarconfig]').attr('data-mindate');
            /*if( ! details.params.dateFrom && defaultDate) {
                let dates = defaultDate.split(' - ');
                //details.params.dates = defaultDate;
                details.splitCalendarDates(defaultDate);
                defaultDate = dates[1] ? [dates[0], dates[1]] : (dates[0] ? dates[0] : []);
            }*/

            let months = 4;
            if (details.params.objectGroupId && details.params.objectGroupId == 6) months = 3;
            if (config && config.showMonths) months = config.showMonths;

            let calendarConfig = calendar.calendar.init(
                $(elem).find('.datepicker-range-book'),
                {
                    availabilityMask: config.availabilityMask,
                    startDate: config.startDate,
                    minStay: config.minStay,
                    periods: config.periods,
                    translations: config.translations,
                    defaultDate: defaultDate ? defaultDate : [],
                    minDate: minDate ? minDate : 'today',
                    mode: config.mode || 'range',
                    inline: config.inline || true,
                    showMonths: months,
                    containerClass: 'details-booking-calendar mx-auto'
                },
                details.callbackOnDatesSelected
            );

            calendarConfig.config.id = index;

            details.calendars.push(calendarConfig)
        })

    },
    inquiryInit()
    {
        let now = functions.cleanDate(new Date);
        let $datesI = null;
        $('.datepicker-range-inquiry').each((i, elem) =>
        {
            const $datepicker = $(elem);
            const minDate = $datepicker.attr('data-min');
            const maxDate = $datepicker.attr('data-max');
            const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
            const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
            const duration = Number($datepicker.attr('data-duration'));
            const position = $datepicker.attr('data-position') || 'auto';
            const minStayText = $datepicker.attr('data-minstay');
            let showMonths = $datepicker.attr('data-months') || '';
            const mode = $datepicker.attr('data-mode') || 'range';

            showMonths = functions.isMobile() ? 1 : showMonths;

            $datesI = $datepicker.flatpickr({
                mode: mode,
                showMonths: showMonths,
                altInput: true,
                wrap: true,
                altFormat: "j.m.Y",
                dateFormat: "Y-m-d",
                defaultDate: defaultDate,
                minDate: minDate && minDate >= now ? minDate : now,
                maxDate: maxDate ? maxDate : null,
                disableMobile: true,
                prevArrow: '<i class="la la-arrow-left la-fw la-2x pr-2"></i>',
                nextArrow: '<i class="la la-arrow-right la-fw la-2x pl-2"></i>',
                locale: {
                    rangeSeparator: ' - '
                },
                position: position,
                minStayText: minStayText ? minStayText : '',
                onDayCreate(dObj, dStr, fp, dayElem)
                {

                    let minStay = 1;
                    let to = new Date(maxDate);
                    let weekday = new Date(dayElem.dateObj).getDay();
                    weekday = '' + (weekday ? weekday : 7);
                    to.setHours(0, 0, 0, 0);
                    let onlyCheckInSelected = fp.selectedDates[0] && !fp.selectedDates[1];
                    // let diff = globals.dateDiff(dayElem.dateObj, fp.selectedDates[0]);
                    let from = dayElem.dateObj;
                    let too = fp.selectedDates[0];
                    // let f = Date.UTC(from.getFullYear(), from.getMonth()+1, from.getDate());
                    // let t = Date.UTC(too.getFullYear(), too.getMonth()+1, too.getDate());

                    let diff = Math.round((from - too) / (1000 * 60 * 60 * 24));

                    if (onlyCheckInSelected)
                    {
                        if (Math.abs(diff) < minStay)
                        {
                            dayElem.className += ' flatpickr-disabled ';
                            if (diff)
                            {
                                if (minStayText)
                                {
                                    $(dayElem).tooltip({
                                        title: minStayText.replace('{$min_stay}', minStay) || ''
                                    });
                                }
                            }
                        }
                    }

                    if (dayElem.dateObj > to || (arrival.length && $.inArray(weekday, arrival) == -1))
                    {
                        dayElem.classList.add('unselectable');
                    }
                },
            });
            // to style clear toggler
            $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
        });

        $('form#inquiry').on('submit', e =>
        {
            e.preventDefault();


            $('.contact-btn').addClass('d-none');
            $('.contact-spinner').removeClass('d-none');
            $('.contact-spinner').addClass('d-flex');

            let params = {};
            let allParams = $(e.currentTarget).serializeArray();
            $.each(allParams, function (i, field)
            {
                params[field.name] = field.value;
            });

            params['dates'] = params.datesi || '';

            if (!params.dates)
            {
                $datesI.open();
                $('.contact-btn').removeClass('d-none');
                $('.contact-spinner').addClass('d-none');
                $('.contact-spinner').removeClass('d-flex');
                return;
            }


            functions.setFormData($('form#inquiry'), params);
            params['language'] = functions.getLang();

            params['requestId'] = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);
            grecaptcha.ready(function ()
            {
                grecaptcha.execute(functions.getRecaptchaKey(), { action: 'contact' }).then(function (token)
                {
                    params['gRecaptchaToken'] = token;
                    params = functions.getDataForUrl(params);
                    if (params.dateFrom && ! params.dateTo) params.dateTo = params.dateFrom;

                    $.post('/services/inquiry/', params).then(response =>
                    {

                        response.status ? $('.request-success').removeClass('d-none').html(response.data) : $('.request-error').removeClass('d-none').html(response.data);
                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').addClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');

                        setTimeout(() =>
                        {
                            
                            $(".request-error, .request-success").addClass('d-none').html();
                        }, 7000);

                        if (response.status == true)
                        {
                            e.currentTarget.reset();
                        }
                    });
                });
            });
        });
    },
    callbackOnDatesSelected(from, to)
    {

        if (to && !to[1])
        {
            $('.calendar-message').removeClass('d-none').text(details.getCalcErrorMessage({ type: !from ? 'selectDates' : 'selectDepartureDate' }));
            $('.btn-booking').addClass('d-none');
        }
    },
    splitCalendarDates(dates)
    {
        if (dates || dates == '')
        {
            const aDates = dates.split(' - ');
            details.params.dateFrom = aDates[0] ? aDates[0] : null;
            details.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getParams()
    {
        details.params = $.extend({}, details.params, functions.getUrlData(true));
        details.splitCalendarDates(details.params.dates);
    },
    isBookable(data)
    {
        return data.isAvailable && data.calc.total && data.calc.status == 'Active';
    },
    isRequest(data)
    {
        return !data.isAvailable && data.calc.status == 'Request';
    },
    getCalcErrorMessage(error) {
        if (error && details.calcErrorMessages && details.calcErrorMessages[error.message]) {
            return details.calcErrorMessages[error.message].replace('{$condition}', error.condition);
        }
        return details.calcErrorMessages ? details.calcErrorMessages.general : null;
    },
    datesSelected()
    {
        return details.params.dateFrom && details.params.dateTo;
    },

    onChange(e)
    {
        if (e && e.target.name == 'dates')
        {
            let val = e.target.value.split(' - ');

            if (val.length == 2) $('.dates-clear').removeClass('d-none');

            if (val.length != 2 && val[0] != '')
            {
                return;
            }
        }

        if (details.params.objectGroupId && details.params.objectGroupId == 6 && ! details.params.duration)
        {
            // if (details.params.objectTypeId == 49)
            // {
                details.getCalculation()
            // }
        }
    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 6;
            
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-12');
                $('#booking-calendar-slider').addClass('justify-content-xl-center');
                slidesPerView = 6;
                // $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 3));
                // $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 20,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.5,
                            spacing: 10,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 1.7,
                            spacing: 10,

                        },
                    },
                },
            });

            let $departureCards = $('.departure');

            $departureCards.on('click', function(e) {
                $departureCards.filter('.active').removeClass('active');
                let $target = $(e.currentTarget);
                $target.addClass('active');

                let rates = $target.attr('data-rates');
                let dateFrom = $target.attr('data-dateFrom');
                let dateTo = $target.attr('data-dateTo');

                details.params.dateFrom = dateFrom;
                details.params.dateTo = dateTo;
                functions.setUrlData(details.params, true, true);
                details.getCalculation();
            })

            let $active = $departureCards.filter('.active:not(.disabled)');
        
            if ($active.length)
            {
                details.params.dateFrom = $active.attr('data-dateFrom');
                details.params.dateTo = $active.attr('data-dateTo');
            }
        }
    },


    getCalculation(unitId = null)
    {

        const dateFrom = details.params.dateFrom;
        const dateTo = details.params.dateTo;
        unitId = details.params.unitId;

        if (
            (details.params.objectId || unitId)
            && (details.params.guests && details.params.guests !== '0,0')
            && dateFrom
            && dateTo
            && details.params.objectGroupId
        )
        {

            $('.calc-loader').prepend(tplLoader).scrollTop(0);
            $('.calc-wrapper').css('opacity', 0);
            $('.calc-wrapper').css('display', 'none');

            let oldCurr = functions.getCookie('currencyIdTo');
            functions.setCookie('currencyIdTo', 1);

            $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?publicId=' + details.params.objectId)
                + '&guests=' + details.params.guests
                + '&dateFrom=' + details.params.dateFrom
                + '&dateTo=' + details.params.dateTo
                + '&objectGroupId=' + details.params.objectGroupId
                + '&language=' + functions.getLang()
                + '&addAllOptionals=' + 1
            )
                .done(response =>
                {
                    functions.setCookie('currencyIdTo', oldCurr);
                    if (response.status) {
                        const data = response.data || [];
                        this.response = data;
                      
                        Object.entries(data).forEach(unit => {
                            const unitId = unit[0];
                            let unitData = unit[1];
                            const $unit = $('.booking-unit-card[data-unitid=' + unitId + ']');

                            const minPersons = +$unit.attr('data-minpersons');
                            const maxPersons = +$unit.attr('data-maxpersons');
                            const unitCalc = unitData.calc;
                            let persons = null;
                            if (details.params.objectGroupId && details.params.objectGroupId == 6)
                            {
                                persons = unitCalc.guests.split(',');
                                persons = +persons[0] + +persons[1];
                            }
                            

                            const $btnBooking = $unit.find('.btn-booking');
                            if (details.isBookable(unitData)) {
                                const unitCalc = unitData.calc;
                                const calcItems = unitCalc.items;
                                let priceOld = 0.00;
                                let priceOldEUR = 0.00;
                                let discountTitle = null;

                                calcItems.forEach(item => {
                                    // popust za djecu ne ulazi u prikaz discount cijene
                                    if ((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                                        priceOld += item.itemTotal;
                                        priceOldEUR += item.itemTotalEUR;
                                    }
                                    if (item.itemType == 'discount') {
                                        discountTitle = item.itemTitle;
                                        if (item.data && item.data.text) {
                                            discountTitle = item.data.text;
                                        }
                                    }
                                });

                                details.calcTotal = unitCalc.total;

                                const total = exchangeRate.convertPrice(unitCalc.total) + ' ' + exchangeRate.getSymbol();
                                const priceOldFormated = exchangeRate.convertPrice(priceOld) + ' ' + exchangeRate.getSymbol();

                                const totalEUR = functions.formatMoney(unitCalc.totalEUR, 0, '.', ',') + ' kn';
                                const priceOldEURFormated = functions.formatMoney(priceOldEUR, 0, '.', ',') + ' kn';

                                if ($btnBooking.attr('href')) {
                                    $btnBooking.attr('href',
                                        $btnBooking.attr('href').split('?')[0] +
                                        '?unitId=' + unitId +
                                        '&dateFrom=' + details.params.dateFrom +
                                        '&dateTo=' + details.params.dateTo +
                                        '&guests=' + details.params.guests +
                                        '&price=' + unitCalc.total +
                                        '&addAllOptionals=' + 1
                                    );
                                }
                                let guestsText = unitCalc.guests.split(',', 2);
                                guestsText = guestsText.reduce((sum, num) => sum + +num, 0);

                                $btnBooking.removeClass('d-none');
                                $unit.find('booking-buttons').removeClass('d-none');

                                $unit.find('.booking-info').addClass('d-none');

                                $unit.find('.label-from').addClass('d-none');

                                $unit.find('.calc-msg').addClass('d-none');
                                if (! details.params.objectGroupId) 
                                {
                                    $unit.find('.btn-request').removeClass('btn-outline-light');
                                    $unit.find('.btn-request').addClass('btn-outline-white');
                                }

                                $unit.find('.price').removeClass('d-none');
                                $unit.find('.price').html(total);
                                $unit.find('.price-old').html(priceOld && (priceOld > unitCalc.total) ? priceOldFormated : '');
                                $unit.find('.price-converted').html(totalEUR);
                                $unit.find('.price-old-converted').html(priceOld && (priceOld > unitCalc.total) ? priceOldEURFormated : '');

                                $unit.find('.label-total').removeClass('d-none');
                                $unit.find('.acc-label').removeClass('d-none');
                                $unit.find('.prices-label').removeClass('d-none');
                                $unit.find('.booking-buttons').removeClass('d-none');

                                $unit.find('.no-calculation').addClass('d-none');
                                $unit.find('.calculation').removeClass('d-none');
                                $unit.find('.acc-label').addClass('d-none');
                                $unit.find('.booking-guests').html(guestsText);
                                $unit.find('.booking-nights').html(unitCalc.nights);
                                $unit.find('.booking-period').html(functions.formatDate(dateFrom, 'dd.mm.yyyy.') + ' - ' + functions.formatDate(dateTo, 'dd.mm.yyyy.'));
                                $unit.find('.init-info-msg').addClass('d-none');
                                
                            } else {
                                // debugger;
                                $btnBooking.addClass('d-none');
                                $unit.find('.booking-info').addClass('d-none');
                                $unit.find('.init-info-msg').addClass('d-none');
                                if (details.params.objectTypeId == 49)
                                {
                                    let currRate = this.rates.filter(rate => rate.dateFrom < details.params.dateFrom && details.params.dateFrom <= rate.dateTo)[0] || [];
                                    let [adults, childen] = details.params.guests.split(',');

                                    if (currRate && currRate.numberOfPersons && currRate.numberOfPersons != (+adults + +(childen || 0)) )
                                    {
                                        unitData.message = 'per_occupancy';
                                        unitData.condition = currRate.numberOfPersons;
                                    }
                                }
                                let errorMessage = details.getCalcErrorMessage(unitData);
                                // const firstLetter = errorMessage.charAt(0)
                                // const firstLetterCap = firstLetter.toUpperCase()
                                // const remainingLetters = errorMessage.slice(1)
                                
                                // const capitalizedMessage = firstLetterCap + remainingLetters;
                                if (details.params.objectGroupId && details.params.objectGroupId == 6)
                                {
                                    if(persons < minPersons) {
                                        errorMessage = details.getCalcErrorMessage({message: 'minOccupancy', condition: minPersons});
                                    } else if(persons > maxPersons) {
                                        errorMessage = details.getCalcErrorMessage({message: 'maxOccupancy', condition: maxPersons});
                                    }
                                }

                                $unit.find('.calc-msg').removeClass('d-none').html(`<div class="d-flex justify-content-center align-items-start"> 
                                                                                <span class="ps-2 fw-semibold"> ${errorMessage} </span> </div>`, );
                                $unit.find('.label-from').addClass('d-none');
                                $unit.find('.acc-label').addClass('d-none');
                                $unit.find('.prices-label').addClass('d-none');
                                $unit.find('.booking-buttons').addClass('d-none');
                                $unit.find('.label-total').addClass('d-none');
                                $unit.find('.label-total').addClass('d-none');
                                $unit.find('.price').addClass('d-none');
                                $unit.find('.acc-label').addClass('d-none');
                                $unit.find('.price-old').addClass('d-none');

                                $unit.find('.no-calculation').removeClass('d-none');
                                $unit.find('.calculation').addClass('d-none');

                                ['.price-old', '.price', '.price-old-converted', '.price-converted'].forEach((elem, i) => {
                                    let $elem = $unit.find(elem);
                                    if ($elem.length) {
                                        let curr = $elem.attr('data-currency');
                                        let price = $elem.attr('data-price');
                                        if (price && price !== '0,00' && price !== '0.00' && price !== '0') {
                                            let formatedPrice = price + ' ' + curr;
                                            if (curr == 'kn') formatedPrice = price + ' ' + curr;
                                            $elem.html(formatedPrice);
                                        }
                                        else {
                                            $elem.html('');
                                        }
                                    }
                                })
                            }

                            setTimeout(() => {
                                let wh = $(window).outerHeight();
                                $('.calc-loader').removeClass('loading').find('.list-loader').remove();
                                $('.calc-wrapper').css('opacity', 1);
                                $('.calc-wrapper').css('display', 'flex');
                                if (functions.isMobile() && details.focusOnBooking) functions.scrollToElement($('.booking-scroll-hook'), wh);
                            }, 450)

                        });
                    }

                }).fail(function (error)
                {
                    functions.setCookie('currencyIdTo', oldCurr);
                    console.log(error);
                });
            
        }

        else
        {
            $('.btn-booking').addClass('d-none');
            $('.no-calculation').removeClass('d-none');
            $('.calculation').addClass('d-none');
            $('.btn-select-dates').removeClass('d-none');
            // $('.acc-label').addClass('d-none');

            $('.btn-booking').addClass('d-none');

            // if (details.params.objectGroupId && details.params.objectGroupId == 6)
            // {
                $('.label-from').removeClass('d-none');
                $('.label-total').addClass('d-none');
    
                $('.no-calculation').removeClass('d-none');
                $('.calculation-text').addClass('d-none');
                $('.price').removeClass('d-none');
    
                ['.price-old', '.price', '.price-old-converted', '.price-converted'].forEach((elem, i) =>
                {
                    let $elem = $(elem);
                    if ($elem.length)
                    {
                        $elem.each((index, $elem2) => {
                            $elem2 = $($elem2);
                            
                            if ($elem.length && ! $elem2.parents('.card.v0').length)
                            {
                                let curr = $elem2.attr('data-currency');
                                let price = $elem2.attr('data-price');
                                if (price && price !== '0,00' && price !== '0.00' && price !== '0')
                                {
                                    let formatedPrice = curr + ' ' + price;
                                    if (i < 2) formatedPrice = price + ' ' + curr;
                                    $elem2.html(formatedPrice);
                                }
                                else
                                {
                                    $elem2.html('');
                                }
                            }
                        })
                    }
                })
    
                $('.no-calculation').removeClass('d-none');
                $('.calculation').addClass('d-none');
    
                $('.calc-message').addClass('d-none');
                $('.calc-msg').addClass('d-none');
                $('.unit-price-container').removeClass('d-none');
            // }
            
            $('.init-info-msg').removeClass('d-none');
            
            $('.total-old').toggleClass('d-none', !$('.total-old').attr('data-minPriceHasDiscount'));
            $('.total-old').text($('.total-old').attr('data-minPriceHasDiscount') ? $('.total-old').attr('data-minPriceOld') : '');

        }
    },

    showInfoText(dates, guests) {
        if (! dates || guests == '0,0' && ! (details.params.objectGroupId && details.params.objectGroupId == 6)) {
            $('.calc-msg').removeClass('d-none').html(`<div class="d-flex justify-content-center align-items-start"> <i class="las la-info-circle"> </i> 
            <span class="ps-2 small"> For an accurate price calculation, select the dates and enter the number of people </span> </div>`, );
            $('.label-total').addClass('d-none');
            $('.prices-label').addClass('d-none');
            $('.acc-label').addClass('d-none');
            $('.btn-request').addClass('btn-outline-light');
        }       

        else return '';
    }
}