import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            // BOOKING PAYMENT TYPE CHECKBOX
            if($('[name="paymentType"]')[0]) {
                checkPaymentType();
                $('[name="paymentType"]').on('change', e => {
                    checkPaymentType();
                });

                function checkPaymentType() {
                    $('[name="paymentType"]').each((index, item) => {
                        const $item = $(item);
                        const id = $item.attr('id');
                        $('[for=' + id + ']').find('[id='  + id + ']').prop('checked', $item.is(':checked'));
                    });
                }
            }

            booking.$form = $(".booking-form");
            booking.$calculation = $(".table-calculation");
            booking.$payment = $(".payment-details");
            booking.params = {};

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            $(document).on('removed-from-cart', function(e, idToRemove) {
               

                $.get('/services/booking_form_cart/').then(response => {
                    response = JSON.parse(response);

                    if (response.status) 
                    {
                        $('.calculation-details').html(response.html);
                        booking.calc = [];
                        booking.$form = $(".booking-form");
                        booking.$calculation = $(".table-calculation");
                        booking.$calculation.find("*[data-calc]").each(function (index) {
                            let item = JSON.parse($(this).attr('data-calc'));
                            item.inCalc = item.isOptional ? 0 : 1;
                            booking.calc.push(item);
                        });
                        booking.total();
                        booking.$payment = $(".payment-details");
                    }
                });
            })

            $('.booking-page').on('change', 'input[type="checkbox"]', function (e) {
                let $row = $(e.currentTarget).closest('.calc-item');
                let $quantity = $row.find('[name=quantity]');
                let value = +$quantity.val();
                let checked = $(e.currentTarget).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', $quantity.find('option').eq(1).val() || 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(e.currentTarget));
            });

            $('.booking-page').on('change', 'select.quantity', function (e) {
                let $row = $(e.currentTarget).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(e.currentTarget).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(e.currentTarget));
            });

            let checkedOptionals = functions.getCookie('checkedOptionals') || '';
            checkedOptionals = checkedOptionals ? JSON.parse(checkedOptionals) : [];

            booking.total();

            booking.$form.on('submit', e => {
                $('button[type="submit"]').attr('disabled', 'disabled');
                booking.submit();
            });

            checkedOptionals.forEach(function(id) {
                $(`#${id}`).trigger('click');
            })

            $('option:selected').each((index, option) => {
                let $elem = $(option);

                $elem.parents('.calc-item').find('input:checkbox:not(:checked)').trigger('click');
            })
            // if ($('.is-only-var').length) {
            //     $("input:checkbox:not(:checked)").trigger('click');
            // }

            const $partnerCodeSuccess = $(".partnerCodeSuccess");
            const $partnerCodeError = $(".partnerCodeError");

            booking.$pricingContainer = $(".table-calculation");
            $('#partnerCodeApply').on('click', function (e) {
                e.preventDefault();

                let params = functions.getUrlData(false);
                params.partnerCode = $("[name='partnerCode']").val();
                params.language = functions.getLang();

                $partnerCodeSuccess.addClass('d-none');
                $partnerCodeError.addClass('d-none');

                $.get('/services/booking_form/', $.param(params)).then(response => {
                    if (response.status) {
                        booking.$pricingContainer.html(response.html);
                        
                        $partnerCodeSuccess.removeClass('d-none');
                        $partnerCodeError.addClass('d-none');
                    } else {
                        $partnerCodeSuccess.addClass('d-none');
                        $partnerCodeError.removeClass('d-none');
                    }
                });
            });

            // recalculate if guests birthDates are different than in initial calc
            $('.guests select[name]').on('change', function (e) {
                const $guestsRow = $(this).closest('.guests');
                const calcId = $guestsRow.attr('data-guests-parentid');
                let calc = booking.calc.find((calc, i) => calc.calcId == calcId);
                if ( !calc ) {
                    calc = functions.getUrlData(false);
                    calc.cart_data = JSON.parse(calc.cart_data);
                    calc = calc.cart_data[calcId-1];
                }
                const dateFrom = calc.dateFrom;
                const guests = (calc.guests || '').split(',');
                const adults = +guests[0];
                const children = +guests[1] || 0;
                const childrenAges = guests.slice(2, 2+ children).sort((a,b) => a < b ? 1 : -1);
                
                let _adults = 0;
                let _childrenAges = [];
                $guestsRow.find('[data-guests]').map((i, elem) => {
                    let year = (+$guestsRow.find('select[name="birthDateYear' + i + '"]').val() || '') + '';
                    let month = (+$guestsRow.find('select[name="birthDateMonth' + i + '"]').val() || '') + '';
                    let day = (+$guestsRow.find('select[name="birthDateDay' + i + '"]').val() || '') + '';
                    if ( year && month && day ) {
                        let age = new Date(new Date(dateFrom) - new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)).getFullYear() - 1970;
                        if ( age >= 18 ) {
                            _adults++;
                        } else {
                            _childrenAges.push(String(age));
                        }
                    }
                });
                _childrenAges.sort((a,b) => a < b ? 1 : -1);
                // continue only if all guests are populated
                if ( (adults + childrenAges.length) == (_adults + _childrenAges.length) ) {
                    // AND different from initial guests
                    if ( adults != _adults || childrenAges.join(',') != _childrenAges.join(',') ) {
                        let newGuests = `${_adults},${_childrenAges.length}${_childrenAges.length ? ',' + _childrenAges.join(',') : ''}`;
                        let params = booking.recalculateParams || functions.getUrlData(false);
                        params.cart_data = JSON.parse(params.cart_data);
                        params.cart_data[calcId-1].guests = newGuests;
                        params.cart_data = JSON.stringify(params.cart_data);
                        params.language = functions.getLang();
                        booking.recalculateParams = params;
                        $.get('/services/booking_form_cart/', $.param(booking.recalculateParams)).then(response => {
                            if ( response ) {
                                response = JSON.parse(response)
                                $('.cart-booking').html(response.html);
                                booking.calc = [];
                                booking.$form = $(".booking-form");
                                booking.$calculation = $(".table-calculation");
                                booking.$calculation.find("*[data-calc]").each(function (index) {
                                    let item = JSON.parse($(this).attr('data-calc'));
                                    item.inCalc = item.isOptional ? 0 : 1;
                                    booking.calc.push(item);
                                });
                                booking.total();
                                booking.$payment = $(".payment-details");
                                window.observer.observe();
                                confirm('Zbog promena u godinama gostiju došlo je do novog obračuna.\nProverite pre nego nastavite.');
                                /*$('.guests-recalculate')
                                    .removeClass('d-none')
                                    .delay(6000).queue(function () {
                                        $(this).addClass('d-none');
                                    });*/
                            }
                        });
                    }
                }
            });

            console.log(booking.params);

        },
        submit() {
            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                } else if (item.itemDefinitionId.categoryId.key == 'baseRate') {
                    let $guestsPerProduct = $("[data-guests-parentId='" + item.calcId + "']");
                    let guests = [];

                    if ($guestsPerProduct.length) {
                        $guestsPerProduct.find('[data-guests]').each(function (index) {
                            let g = +$(this).data('guests');
                            guests[index] = {
                                firstName : $(this).find("input[name='guestFirstName" + g + "']").val(),
                                lastName : $(this).find("input[name='guestLastName" + g + "']").val(),
                                birthDate : $(this).find("*[name='birthDateYear" + g + "']").length ? $(this).find("*[name='birthDateYear" + g + "']").val() + '-' + $(this).find("*[name='birthDateMonth" + g + "']").val() + '-' + $(this).find("*[name='birthDateDay" + g + "']").val() : null,
                                gender : $(this).find("*[name='gender" + g + "']").length ? $(this).find("*[name='gender" + g + "']").val() : null,
                            };
                        });
                        booking.calc[key].guestsData = guests;
                    }
                }
            });

            booking.params.paymentType = booking.$payment.find("[name='paymentType']:checked").val() ? booking.$payment.find("[name='paymentType']:checked").val() : 'bankTransfer';
            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            let year = (+$('select[name="birthDateYear"]').val() || '') + '';
            let month = (+$('select[name="birthDateMonth"]').val() || '') + '';
            let day = (+$('select[name="birthDateDay"]').val() || '') + '';
            booking.params.birthDate = year + '-' + month + '-' + day;

            functions.setCookie('ux_cart_data', '');
            functions.setCookie('cart_data', '');
            functions.setCookie('checkedOptionals', '');
            
            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem) {
            let $row = $elem.closest('.calc-item');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');

            let $quantity = $row.find('select.quantity:visible');
            let quantity = null;

            if ($quantity.length) {
                quantity = +$quantity.val();
            }

            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {

                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }

                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);
                    booking.calc[key].totalConverted = booking.itemTotalConverted(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.') + ' ' + functions.getCurrencySymbol());
                    $row.find('.item-total-amount-converted').html(functions.formatMoney(booking.calc[key].totalConverted, 2, ',', '.'));
                }
            });


            booking.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek', 'Once']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek', 'Once']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total() {
            booking.totalAmount = 0.00;
            booking.totalAmountConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    booking.totalAmount += booking.itemTotal(calc);
                    booking.totalAmountConverted += booking.itemTotalConverted(calc);
                }
            });

            $('.total-amount').html(functions.formatMoney(booking.totalAmount, 2, ',', '.'));
            $('.cart-total-amount').html(functions.formatMoney(booking.totalAmount, 2, ',', '.'));
            $('.total-amount-converted').html(functions.formatMoney(booking.totalAmountConverted, 2, ',', '.'));

            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');
            const advance = booking.totalAmount * (advancePaymentPercent / 100);
            const rest = booking.totalAmount - advance;

            $('.advance').html(functions.formatMoney(advance, 2, ',', '.'));
            $('.rest').html(functions.formatMoney(rest, 2, ',', '.'));

            const advanceConverted = booking.totalAmountConverted * (advancePaymentPercent / 100);
            const restConverted = booking.totalAmountConverted - advanceConverted;

            $('.advance-converted').html(functions.formatMoney(advanceConverted, 2, ',', '.'));
            $('.rest-converted').html(functions.formatMoney(restConverted, 2, ',', '.'));

        }
    }

    booking.init();
});